import {authenticator} from "otplib";

const secret = "GBAHNGELHUK4VEPWDH4U2GW75CZMBYLO"

const token_element = document.getElementById("token");
const time_left_element = document.getElementById("timeleft");
let token = authenticator.generate(secret);
token_element.innerText = token;
const refresh = () => {
    const new_token = authenticator.generate(secret);
    if (new_token !== token) {
        token = new_token;
        token_element.innerText = token;
    }
    time_left_element.innerText = authenticator.timeRemaining();
}

setInterval(refresh, 1000);
